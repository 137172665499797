import React, { useCallback } from "react";
import { Formik } from "formik";
import { toast } from "react-hot-toast";
import { idMapNumeric } from "../../../../providers/iheartjane/jane-embed";
import ProductDetailForm from "../../ProductDetailForm";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogEvent } from "@col-care/common/utilities/index";

import * as addToCartSectionStyles from "./index.module.css";
import { brands } from "../../../../providers/contentstack/content-types/fragments";

const toastOpts = {
  success: {
    style: {
      background: "green",
    },
  },
  error: {
    style: {
      background: "red",
    },
  },
};

const AddToCartSection = (props) => {
  const styles = addToCartSectionStyles.default || addToCartSectionStyles;
  const currentHit = props.currentHit;
  const currentItem = props.currentItem;
  const currentQty = props.currentQty;
  const setCurrentItem = props.setCurrentItem;
  const setCurrentQty = props.setCurrentQty;
  const setShowCartModal = props.setShowCartModal;
  const mainPhoto = props.mainPhoto;
  const itemPrice = props.itemPrice;
  const productName = props.productName;
  const productBrand = props.productBrand;
  const cartData = props.cartData;
  const productType = props.productType;
  const itemSpecial = props.itemSpecial;
  const specialTypes = props.specialTypes;
  const specialIds = props.specialIds;
  const bundleSpecialIds = props.bundleSpecialIds;
  const brandSpecialIds = props.brandSpecialIds;
  const bulkSpecialIds = props.bulkSpecialIds;
  const posthog = props?.posthog;

  const handleSubmit = useCallback(
    (values, actions) => {
      const maxQtyForItem =
        currentHit[`max_cart_quantity_${currentItem}`] ||
        currentHit.max_cart_quantity;
      // we want to check the requested qty + the existing cart qty

      const matchingCartItem = cartData.products.find(
        (product) => currentHit.product_id === product.productId
      );

      if (matchingCartItem === undefined) {
        toast.dismiss();
        props.cartActions.session.doAddToCart({
          productId: currentHit.product_id,
          priceId: currentItem,
          count: currentQty,
          photo: mainPhoto,
          name: productName,
          brand: productBrand,
          type: productType,
          maxQty: maxQtyForItem,
          itemSpecial: itemSpecial,
          price: parseFloat(itemPrice),
          specialTypes: specialTypes,
          specialIds: specialIds,
          bundleSpecialIds: bundleSpecialIds,
          brandSpecialIds: brandSpecialIds,
          bulkSpecialIds: bulkSpecialIds,
          pathname: `/shop/${currentHit.custom_product_type}/${currentHit.unique_slug}`,
          timestamp: Date.now(),
          max_qty: {
            qty: currentHit.max_cart_quantity,
            qty_each: currentHit.max_cart_quantity_each,
            qty_eighth_ounce: currentHit.max_cart_quantity_eighth_ounce,
            qty_gram: currentHit.max_cart_quantity_gram,
            qty_half_gram: currentHit.max_cart_quantity_half_gram,
            qty_half_ounce: currentHit.max_cart_quantity_half_ounce,
            qty_ounce: currentHit.max_cart_quantity_ounce,
            qty_quarter_ounce: currentHit.max_cart_quantity_quarter_ounce,
            qty_two_gram: currentHit.max_cart_quantity_two_gram,
          },
        });
        setShowCartModal(true);

        capturePosthogEvent({
          eventName: posthogEvents.ADD_TO_CART_MODAL_OPENED,
          posthog,
          databag: {
            cartData,
          },
          // details: { cartData },
        });
      } else if (
        !maxQtyForItem ||
        (maxQtyForItem &&
          (!matchingCartItem ||
            (matchingCartItem &&
              maxQtyForItem < matchingCartItem.count + currentQty)))
      ) {
        toast.dismiss();
        toast.error("Quantity limit reached", toastOpts);
        console.info("max qty", maxQtyForItem);
        console.info("requested total", matchingCartItem.count + currentQty);
      } else {
        toast.dismiss();
        props.cartActions.session.doAddToCart({
          productId: currentHit.product_id,
          priceId: currentItem,
          count: currentQty,
          photo: mainPhoto,
          name: productName,
          brand: productBrand,
          type: productType,
          maxQty: maxQtyForItem,
          itemSpecial: itemSpecial,
          price: parseFloat(itemPrice),
          specialTypes: specialTypes,
          specialIds: specialIds,
          bundleSpecialIds: bundleSpecialIds,
          brandSpecialIds: brandSpecialIds,
          bulkSpecialIds: bulkSpecialIds,
          pathname: `/shop/${currentHit.custom_product_type}/${currentHit.unique_slug}`,
          timestamp: Date.now(),
          max_qty: {
            qty: currentHit.max_cart_quantity,
            qty_each: currentHit.max_cart_quantity_each,
            qty_eighth_ounce: currentHit.max_cart_quantity_eighth_ounce,
            qty_gram: currentHit.max_cart_quantity_gram,
            qty_half_gram: currentHit.max_cart_quantity_half_gram,
            qty_half_ounce: currentHit.max_cart_quantity_half_ounce,
            qty_ounce: currentHit.max_cart_quantity_ounce,
            qty_quarter_ounce: currentHit.max_cart_quantity_quarter_ounce,
            qty_two_gram: currentHit.max_cart_quantity_two_gram,
          },
        });
        setShowCartModal(true);

        capturePosthogEvent({
          eventName: posthogEvents.ADD_TO_CART_MODAL_OPENED,
          posthog,
          databag: {
            cartData,
          },
        });
      }
    },
    [currentQty]
  );

  const specialPriceTypes = [
    "special_price_each",
    "special_price_eighth_ounce",
    "special_price_half_gram",
    "special_price_half_ounce",
    "special_price_ounce",
    "special_price_gram",
    "special_price_quarter_ounce",
  ];
  const isBundle = props.hit?.applicable_special_types?.indexOf("bundle") > -1;
  const color = isBundle ? "#7ebd5a" : null;

  function renderQuantityValue(currentHit) {
    if (currentHit.amount) {
      if (currentHit.kind === "pre-roll") {
        if (currentHit.amount.includes("pk")) {
          return <span>{currentHit.amount}</span>;
        } else {
          const quantity =
            currentHit.net_weight_grams === parseFloat(currentHit.amount)
              ? 1
              : Math.ceil(
                  currentHit.quantity_value / parseFloat(currentHit.amount)
                );
          return <span>{quantity === 0 ? "each" : `${quantity}pk`}</span>;
        }
      } else {
        return <span>{currentHit.amount}</span>;
      }
    } else {
      return <span>{idMapNumeric[currentItem]}</span>;
    }
  }
  const renderSpecialPrice = () => {
    for (const priceType of specialPriceTypes) {
      const priceData = props.hit[priceType];
      if (
        priceData?.discount_amount &&
        priceType === `special_price_${currentItem}`
      ) {
        return (
          <div id={styles.item__price_discount} className={styles.item__price}>
            {/* Discounted Price */}$
            {parseFloat(priceData.discount_price).toFixed(2)}/
            <span className={styles.grams}>
              {renderQuantityValue(currentHit)}
            </span>
            <span className={styles.old_price}>
              {/* Original Price */}${parseFloat(props.itemPrice)}
            </span>
          </div>
        );
      }
    }

    // If the calculated price is NaN, use sort_price
    const calculatedPrice = parseFloat(props.itemPrice);
    const finalPrice = isNaN(calculatedPrice)
      ? parseFloat(props.hit.sort_price)
      : calculatedPrice;

    // If no special price is available, render the regular price
    return (
      <div className={styles.item__price} style={{ color }}>
        ${finalPrice.toFixed(2)}/
        <span className={styles.grams}>{renderQuantityValue(currentHit)}</span>
      </div>
    );
  };

  let _item;

  return (
    <>
      {renderSpecialPrice()}
      {props.hit?.special_title && (
        <div className={styles.special_title}>{props.hit.special_title}</div>
      )}

      <div className={styles.weight_section}>
        <Formik
          initialValues={{ quantity: { currentQty } }}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {};

            // if (!values.quantity) {
            //   errors.quantity = "Please enter a quantity";
            // }

            return errors;
          }}
        >
          {(props) => {
            return (
              <ProductDetailForm
                currentHit={currentHit}
                currentItem={currentItem}
                currentQty={currentQty}
                setCurrentItem={setCurrentItem}
                setCurrentQty={setCurrentQty}
                handleSubmit={handleSubmit}
                posthog={posthog}
                cartData={cartData}
                {...props}
              />
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddToCartSection;
