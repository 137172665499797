// extracted by mini-css-extract-plugin
export var check_store_text = "index-module--check_store_text--98349";
export var close_me = "index-module--close_me--5280e";
export var extract__section = "index-module--extract__section--b8000";
export var hide__mobile = "index-module--hide__mobile--9695b";
export var item__brand = "index-module--item__brand--cfe1f";
export var item__category = "index-module--item__category--a9633";
export var item__container = "index-module--item__container--50fbb";
export var item__description = "index-module--item__description--74583";
export var item__image = "index-module--item__image--d7bf6";
export var item__info = "index-module--item__info--36c8d";
export var item__name = "index-module--item__name--d90a3";
export var item__rating = "index-module--item__rating--072e3";
export var item__subtype = "index-module--item__subtype--d5435";
export var item__thc = "index-module--item__thc--71bb5";
export var item__three_category = "index-module--item__three_category--5ba79";
export var open = "index-module--open--9dc81";
export var opensvg = "index-module--opensvg--1fa63";
export var product_location = "index-module--product_location--e4253";
export var sales__tag = "index-module--sales__tag--bc2f9";
export var sales__tag__similar = "index-module--sales__tag__similar--f2dd0";
export var sales__tag__similar_mix = "index-module--sales__tag__similar_mix--583ed";
export var sales__tag_green = "index-module--sales__tag_green--a04d8";
export var sales__tag_text = "index-module--sales__tag_text--e596a";
export var sales__tag_text_container = "index-module--sales__tag_text_container--4a2b4";
export var sales__tag_text_mix_container = "index-module--sales__tag_text_mix_container--360dc";
export var show__mobile = "index-module--show__mobile--16b85";
export var similar_items = "index-module--similar_items--c397d";