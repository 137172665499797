// extracted by mini-css-extract-plugin
export var button_container = "productlocations-tgs-module--button_container--4ba5f";
export var input_section = "productlocations-tgs-module--input_section--e1844";
export var instock_location = "productlocations-tgs-module--instock_location--ca012";
export var location__address = "productlocations-tgs-module--location__address--e7552";
export var location__container = "productlocations-tgs-module--location__container--9f13d";
export var location__header_info = "productlocations-tgs-module--location__header_info--107d6";
export var location__info = "productlocations-tgs-module--location__info--caef6";
export var notinstock_location = "productlocations-tgs-module--notinstock_location--469f4";
export var pickup__button = "productlocations-tgs-module--pickup__button--c4c7b";
export var pickup__button_diable = "productlocations-tgs-module--pickup__button_diable--89126";
export var product_locations_container = "productlocations-tgs-module--product_locations_container--b643d";
export var view_locations = "productlocations-tgs-module--view_locations--0d35c";