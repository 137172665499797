import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { orderByDistance, getPreciseDistance, convertDistance } from "geolib";

import Spinner from "../../elements/spinner/Spinner";
import ProductLocationsForm from "./ProductLocationsForm";
import ProductLocationsResults from "./ProductLocationsResults";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogEvent } from "@col-care/common/utilities/index";

import * as tgsStyles from "./variants/productlocations-tgs.module.css";
import * as locationStyle from "./locations.module.css";

const ProductLocationsBlock = (props) => {
  const locationStyles = locationStyle.default || locationStyle;
  const posthog = props?.posthog;

  // console.log("ProductLocationsBlock props", props);

  let styles;
  switch (props.variant) {
    case "tgs":
      styles = tgsStyles.default || tgsStyles;
      break;
  }

  // const state = useSelector((state) => state);
  // const locations = state.hydrate.databagData.locations;
  const locations = props.locations;

  const locationsByUrl = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          if (v.hasProduct) {
            if (
              props.availableAt.includes(
                parseInt(v.content.vendor_integrations.jane_rec_id)
              )
            ) {
              v.hasProduct = true;
            } else {
              v.hasProduct = false;
            }
          }
          a[v.url] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  let sortedCoordinates;
  const unsortedCoordinates = locations
    ? locations.reduce((a, v) => {
        if (!v.content.geolocation.lat || !v.content.geolocation.lng) {
          return a;
        }
        const thing = {
          url: v.url,
          title: v.title,
          lat: v.content.geolocation.lat,
          lng: v.content.geolocation.lng,
          content: v.content,
        };
        if (thing.hasProduct) {
          if (
            props.availableAt.includes(
              parseInt(v.content.vendor_integrations.jane_rec_id)
            )
          ) {
            thing.hasProduct = true;
          } else {
            thing.hasProduct = false;
          }
        }
        a.push(thing);
        return a;
      }, [])
    : [];

  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [currentZip, setCurrentZip] = useState("");
  const [zipTail, setZipTail] = useState("");
  const [closestStore, setClosestStore] = useState(null);
  const [sortedStores, setSortedStores] = useState(null);
  const [classes, setClasses] = useState(false);
  const [activeClasses, setActiveClasses] = useState();
  const [loading, setLoading] = useState(false);

  const resultsRef = useRef(null);

  useEffect(() => {
    if (apiData && apiData.coordinates) {
      const sortedStoreArray = [];
      const coordSet = apiData.coordinates[zipTail];
      sortedCoordinates = orderByDistance(coordSet, unsortedCoordinates);

      if (props.currentStore) {
        const _store = sortedCoordinates.find(
          (store, key) => store.content.id === props.currentStore.content.id
        );
        sortedStoreArray.push(_store);

        sortedCoordinates.map((v, k) => {
          if (v.hasProduct && v.content.id !== props.currentStore.content.id) {
            sortedStoreArray.push(v);
          }
        });

        sortedCoordinates.map((v, k) => {
          if (!v.hasProduct && v.content.id !== props.currentStore.content.id) {
            sortedStoreArray.push(v);
          }
        });
      } else {
        sortedCoordinates.map((v, k) => {
          if (v.hasProduct) {
            sortedStoreArray.push(v);
          }
        });

        sortedCoordinates.map((v, k) => {
          if (!v.hasProduct) {
            sortedStoreArray.push(v);
          }
        });
      }

      setSortedStores(sortedStoreArray);
    }
  }, [apiData]);

  return (
    <section
      className={`${styles.product_locations_container}`}
      id={"product-locations-section"}
    >
      <Formik
        initialValues={{
          zip: null,
        }}
        validate={(values) => {
          // console.log(values);
          const errors = {};

          if (!values.zip) {
            errors.zip = "Please enter a ZIP code";
          }

          if (values.zip && values.zip.length < 5) {
            errors.zip = "Need at least 5 characters";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setCurrentZip(values.zip);

          capturePosthogEvent({
            eventName: posthogEvents.PRODUCT_LOCATIONS,
            title: `Searched for zip code ${values.zip}`,
            posthog,
            databag: {
              cartData: props?.cartData,
            },
          });

          const strLength = values.zip.length || 0;
          if (strLength >= 3 && strLength <= 5) {
            // @todo: gotta have a list of possible hostnames
            const path = `${props.hostname}${
              props.apiPath
            }/${values.zip.substring(0, 3)}/page-data.json`;

            const fetchOptions = {
              method: "GET",
              mode: "cors",
              cache: "default",
            };

            const fetchRequest = new Request(path, fetchOptions);

            setLoading(true);

            fetch(fetchRequest)
              .then((response) => {
                response.json().then((data) => {
                  if (data.result.pageContext.content?.apiData?.coordinates) {
                    setApiData(data.result.pageContext.content.apiData);
                  } else {
                    console.log("no API data found!");
                    setApiData(null);
                  }
                  setTimeout(() => {
                    setLoading(false);
                  }, 550);
                });
              })
              .catch((err) => {
                setLoading(false);
                setApiError(err);
              });
          }
          return false;
        }}
      >
        {({
          values,
          errors,
          touched,
          resetForm,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status,
        }) => {
          return (
            <ProductLocationsForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              resetForm={resetForm}
              setZipTail={setZipTail}
              currentZip={currentZip}
              isSubmitting={isSubmitting}
              status={status}
              setActiveClasses={setActiveClasses}
              apiData={apiData}
              setApiData={setApiData}
              setSortedStores={sortedStores}
              {...props}
            />
          );
        }}
      </Formik>
      <div
        id={styles.product_results}
        className={locationStyles.locations_result}
        ref={resultsRef}
      >
        {loading && (
          <div className={locationStyles.locations_spinner}>
            <Spinner />
          </div>
        )}
        <ProductLocationsResults
          apiData={apiData}
          currentZip={currentZip}
          locationsByUrl={locationsByUrl}
          unsortedCoordinates={unsortedCoordinates}
          closestStore={closestStore}
          setClosestStore={setClosestStore}
          classes={activeClasses}
          setClasses={setActiveClasses}
          variant={props.variant}
          sortedStores={sortedStores}
          currentStore={props.currentStore}
          posthog={props?.posthog}
          locations={locations}
          availableAt={props.availableAt}
        />
      </div>
    </section>
  );
};

export default ProductLocationsBlock;
