import React from "react";
import { navigate } from "gatsby";

import Button from "../../elements/button/Button";
import ProductLocationsList from "./locations-list";

import * as tgsStyles from "./variants/productlocations-tgs.module.css";

const ProductLocationsResults = (props) => {
  const toggleClass = (index) => {
    props.setClasses(index);
  };

  let styles;
  let theme;

  switch (props.variant) {
    case "tgs":
      styles = tgsStyles.default || tgsStyles;
      theme = "tgsSelect";
      break;
  }

  if (!props.apiData) {
    let sorted = [];
    let _sorted = props.unsortedCoordinates.sort((a, b) =>
      a.content?.state?.localeCompare(b.content?.state)
    );

    if (props.currentStore) {
      const _store = _sorted.find(
        (store, key) => store.content.id === props.currentStore.content.id
      );

      if (_store) sorted.push(_store);

      _sorted.map((v, k) => {
        if (v.hasProduct && v.content.id !== props.currentStore.content.id) {
          sorted.push(v);
        }
      });

      _sorted.map((v, k) => {
        if (!v.hasProduct && v.content.id !== props.currentStore.content.id) {
          sorted.push(v);
        }
      });
    } else {
      _sorted.map((v, k) => {
        if (v.hasProduct) {
          sorted.push(v);
        }
      });

      _sorted.map((v, k) => {
        if (!v.hasProduct) {
          sorted.push(v);
        }
      });
    }

    // console.log({ sortesorteddStores: sorted });

    return (
      <>
        <ProductLocationsList
          data={sorted}
          locationsByUrl={props.locationsByUrl}
          variant="tgs"
          setClasses={props.setClasses}
          currentStore={props.currentStore}
          hasProductCheck
          posthog={props?.posthog}
          availableAt={props.availableAt}
        />
        {!!sorted?.length && (
          <div className={styles.button_container}>
            <Button
              onClick={(e) => {
                navigate(`/locations`);
              }}
              theme="tgsfilterbtn"
              label={
                sorted.filter((val) => val?.hasProduct).length <= 1
                  ? "Only available at this location"
                  : "View All locations"
              }
              disabled={sorted.filter((val) => val?.hasProduct).length <= 1}
            />
          </div>
        )}
      </>
    );
  }

  if (!props.sortedStores) {
    return <h4>Sorry, nothing here.</h4>;
  }

  // console.log({ sortedStores: props.sortedStores });
  return (
    <ProductLocationsList
      data={props.sortedStores}
      locationsByUrl={props.locationsByUrl}
      variant="tgs"
      setClasses={props.setClasses}
      currentStore={props.currentStore}
      classes={props.classes}
      posthog={props?.posthog}
      availableAt={props.availableAt}
    />
  );
};

export default ProductLocationsResults;
