import React from "react";
import { useSelector, useStore } from "react-redux";

import { getBoundActions } from "../state/actions";
import ProductDetail from "@col-care/common/blocks/product-detail/ProductDetail";
import Modal from "../components/modal/Modal";
import Button from "../components/button/Button";

export default function ShopCategoryName(props) {
  const store = useStore();
  const actions = getBoundActions(store.dispatch);
  const user = useSelector((state) => state.auth.currentUser);
  const productData = props.pageContext?.content?.context;
  const cartData = useSelector((state) => state.session.cartData);
  const storeId = cartData?.store?.janeId;
  const locations = useSelector((state) => state.hydrate.databagData.locations);
  const location = useSelector((state) => state.router.location);
  const siteConfigData = useSelector((state) => state.hydrate.siteConfigData);
  const specialsData = useSelector((state) => state.session.specialsData);
  const _persist = useSelector((state) => state._persist);
  const posthog = props?.posthog;

  return (
    <ProductDetail
      actions={actions}
      user={user}
      productData={productData}
      cartData={cartData}
      storeId={storeId}
      locations={locations}
      location={location}
      siteConfigData={siteConfigData}
      specialsData={specialsData}
      searchClient={props.algoliaSearchClient}
      searchHistory={props.algoliaSearchHistory}
      modal={Modal}
      button={Button}
      posthog={posthog}
    />
  );
}
