import React from "react"; // eslint-disable-line

const BackArrow = ({ color, className, height }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.3965 15.4082H6.41992"
      stroke="#375542"
      stroke-width="2.56808"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4082 24.3965L6.41992 15.4082L15.4082 6.41992"
      stroke="#375542"
      stroke-width="2.56808"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BackArrow;
