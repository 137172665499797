// extracted by mini-css-extract-plugin
export var arrow__icon = "index-module--arrow__icon--78e3b";
export var hide__mobile = "index-module--hide__mobile--94957";
export var inout__container = "index-module--inout__container--5c1ed";
export var item__description = "index-module--item__description--f04fa";
export var opensvg = "index-module--opensvg--2bc04";
export var pickup_link = "index-module--pickup_link--f4c0b";
export var pickup_location = "index-module--pickup_location--31be1";
export var show__mobile = "index-module--show__mobile--c1ae9";
export var stock__box = "index-module--stock__box--e21a6";
export var stock__box_out = "index-module--stock__box_out--ac216";