// extracted by mini-css-extract-plugin
export var activities = "productdetail-module--activities--5b088";
export var add__to_cart = "productdetail-module--add__to_cart--1fa2a";
export var arrow__icon = "productdetail-module--arrow__icon--e0d5f";
export var backArrow = "productdetail-module--backArrow--ebfad";
export var count = "productdetail-module--count--b49c7";
export var customer_avatar = "productdetail-module--customer_avatar--bdf9c";
export var customer_comment = "productdetail-module--customer_comment--ce05a";
export var customer_feels = "productdetail-module--customer_feels--42301";
export var customer_name = "productdetail-module--customer_name--c7055";
export var customer_rating = "productdetail-module--customer_rating--b2e4b";
export var grams = "productdetail-module--grams--a6c10";
export var hide__mobile = "productdetail-module--hide__mobile--4c013";
export var hide_for_now = "productdetail-module--hide_for_now--57cc2";
export var item__top_buttons = "productdetail-module--item__top_buttons--64d23";
export var old_price = "productdetail-module--old_price--838de";
export var price__section = "productdetail-module--price__section--6754c";
export var price_on_mobile = "productdetail-module--price_on_mobile--f096a";
export var product__details_header = "productdetail-module--product__details_header--8fa35";
export var product_available_header = "productdetail-module--product_available_header--53b35";
export var product_detail_container = "productdetail-module--product_detail_container--3256a";
export var product_detail_reviews_container = "productdetail-module--product_detail_reviews_container--b4ac7";
export var select_header = "productdetail-module--select_header--3bf8f";
export var show__mobile = "productdetail-module--show__mobile--5a9bf";
export var specialsContainer = "productdetail-module--specialsContainer--f4299";
export var submit__container = "productdetail-module--submit__container--8fd5b";
export var total_review_count = "productdetail-module--total_review_count--bb9b7";
export var total_score = "productdetail-module--total_score--497ec";
export var weight_section = "productdetail-module--weight_section--0cc64";