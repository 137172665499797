import React from "react";

import {
  priceIdMap,
  idPriceMap,
  priceIdMapNumeric,
} from "../../providers/iheartjane/jane-embed";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogEvent } from "@col-care/common/utilities/index";
import * as tgsStyles from "./variants/productdetail-tgs.module.css";

const ProductDetailForm = (props) => {
  const posthog = props?.posthog;

  let styles;

  switch (props.variant) {
    case "tgs":
    default:
      styles = tgsStyles.default || tgsStyles;
      break;
  }

  const currentQty = props.currentQty;
  const currentHit = props.currentHit;
  const currentItem = props.currentItem;

  return (
    <form onSubmit={props.handleSubmit}>
      {(() => {
        return (
          <>
            <p
              style={{
                marginTop: "15px",
              }}
            >
              {props.currentHit?.available_weights?.length <= 1
                ? ``
                : `Select Weight:`}
            </p>
            <span
              style={{
                display: "flex",

                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {getAttributeOptions(
                props.currentHit,
                props.currentItem,
                props.setCurrentItem,
                props.setCurrentQty,
                posthog
              )}
            </span>

            <p className={styles.select_header}>Choose Quantity: </p>
            <div className={styles.add_to_cart_button_container}>
              <button
                type="button"
                className={styles.add_to_cart_button}
                style={{
                  cursor: currentQty === 1 ? "not-allowed" : "pointer",
                  opacity: currentQty === 1 ? "0.5" : "1",
                }}
                id={"decrease-quantity-button"}
                onClick={() => {
                  if (currentQty > 1) {
                    props.setCurrentQty(currentQty - 1);
                    capturePosthogEvent({
                      eventName: posthogEvents.ITEM_QUANTITY_DECREASED,
                      databag: {
                        cartData: props.cartData,
                      },
                      posthog,
                    });
                  }
                }}
              >
                -
              </button>
              <div className={styles.count}>{currentQty}</div>
              <button
                id={"increase-quantity-button"}
                type="button"
                className={styles.add_to_cart_button}
                onClick={() => {
                  const maxQtyForItem =
                    currentHit[`max_cart_quantity_${currentItem}`] ||
                    currentHit.max_cart_quantity;

                  if (currentQty < maxQtyForItem) {
                    props.setCurrentQty((currentQty) => currentQty + 1);

                    capturePosthogEvent({
                      eventName: posthogEvents.ITEM_QUANTITY_INCREASED,
                      // details: {
                      //   store: thisStore.content.id,
                      // },
                      posthog,
                    });
                  }
                }}
              >
                +
              </button>
            </div>

            <div className={styles.submit__container}>
              <button
                aria-label="Add To Cart"
                // disabled={
                //   props.isSubmitting || (props.status && props.status.success)
                // }
                type="submit"
                className={styles.add__to_cart}
                id="add-to-cart-button"
              >
                Add to Cart
              </button>
            </div>
          </>
        );
      })()}
    </form>
  );
};
export default ProductDetailForm;

const getAttributeOptions = (
  currentHit,
  currentItem,
  setCurrentItem,
  setCurrentQty,
  posthog
) => {
  const options = [];
  // this could need tweaking if we run into unreg product w/ attributes
  if (currentHit?.available_weights?.length === 1) {
    const weight = currentHit.available_weights[0];
    options.push(
      <div
        key="single-weight"
        style={{
          padding: "0px 0px 15px",
          fontSize: "14px",
          color: "#375542",
          textTransform: "uppercase",
        }}
      >
        <strong>Weight:</strong>{" "}
        {currentHit.amount ? (
          <span>{currentHit.amount}</span>
        ) : (
          <span>{priceIdMapNumeric[weight]}</span>
        )}
      </div>
    );
  } else {
    currentHit?.available_weights?.map((v, k) => {
      const itemPrice = currentHit[`price_${priceIdMap[v]}`];
      const itemSpecialPrice = currentHit[`special_price_${priceIdMap[v]}`];

      options.push(
        <div
          id={`attribute-options-${priceIdMap[v]}`}
          key={`attribute-options-${k}`}
          onClick={() => {
            // @todo: Need to add Posthog event? When this is called?
            setCurrentQty(1);
            setCurrentItem(priceIdMap[v]);
            capturePosthogEvent({
              eventName: posthogEvents.ITEM_QUANTITY_INCREASED,
              // details: {
              //   store: thisStore.content.id,
              // },
              posthog,
            });
          }}
          style={{
            border:
              priceIdMap[v] === currentItem
                ? "3px solid rgb(126, 189, 90)"
                : "1px solid #375542",
            padding: "10px 0px",
            cursor: "pointer",
            background: priceIdMap[v] === currentItem ? "white" : "transparent",
            color:
              priceIdMap[v] === currentItem
                ? "rgb(126, 189, 90)"
                : "rgba(55, 85, 66, 0.7)",
            fontFamily: "AvenirNextBold",
            textAlign: "center",
            borderRadius: "100px",
            flexBasis: "23%",
            margin: "0px 0px 15px",
          }}
        >
          <div
            style={{
              color: " rgba(55, 85, 66, .7)",
            }}
          >
            {currentHit.amount ? currentHit.amount : priceIdMapNumeric[v]}
          </div>
          <div>
            $
            {(() => {
              if (itemSpecialPrice?.price !== undefined) {
                parseFloat(itemSpecialPrice.price).toFixed(2);
              }
              return parseFloat(itemPrice).toFixed(2);
            })()}
          </div>
        </div>
      );
    });
  }
  return options;
};
