// extracted by mini-css-extract-plugin
export var add__to_cart = "productdetail-tgs-module--add__to_cart--afe27";
export var add_to_cart_button = "productdetail-tgs-module--add_to_cart_button--5b979";
export var add_to_cart_button_container = "productdetail-tgs-module--add_to_cart_button_container--df0b9";
export var count = "productdetail-tgs-module--count--8f43a";
export var grams = "productdetail-tgs-module--grams--833ad";
export var item__price = "productdetail-tgs-module--item__price--517cd";
export var item__price_discount = "productdetail-tgs-module--item__price_discount--eb181";
export var old_price = "productdetail-tgs-module--old_price--d4f9d";
export var select_header = "productdetail-tgs-module--select_header--186d4";
export var special_title = "productdetail-tgs-module--special_title--7c933";
export var submit__container = "productdetail-tgs-module--submit__container--58e67";
export var weight_section = "productdetail-tgs-module--weight_section--cc5de";