import React from "react";
import { toast } from "react-hot-toast";

import CopyIconTgs from "../../../elements/icons/CopyIconTgs";
import FacebookIconTgs from "../../../elements/icons/FacebookIconTgs";
import TwitterIconTgs from "../../../elements/icons/TwitterIconTgs";
import * as socialShareStyles from "./index.module.css";

const toastOpts = {
  success: {
    style: {
      background: "green",
    },
  },
  error: {
    style: {
      background: "red",
    },
  },
};

const styles = socialShareStyles.default || socialShareStyles;
const SocialShare = ({ shareLink = "" }) => (
  <div className={styles.item__share}>
    <h3>SHARE:</h3>
    <div className={styles.item__share_icons}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.tgscolorado.com%2F${
          shareLink ?? ""
        }`}
      >
        <FacebookIconTgs />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://twitter.com/share?ref_src=twsrc%5Etfw&text=Check%20out%20this%20product%20from%20The%20Green%20Solution:&url=https://www.tgscolorado.com${
          shareLink ?? ""
        }`}
      >
        <TwitterIconTgs />
      </a>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          toast.dismiss();
          toast("Link copied to clipboard!", toastOpts);
        }}
      >
        <CopyIconTgs />
      </span>
    </div>
  </div>
);

export default SocialShare;
