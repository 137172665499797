// extracted by mini-css-extract-plugin
export var add__to_cart = "index-module--add__to_cart--49b0d";
export var add_to_cart_button = "index-module--add_to_cart_button--a4101";
export var add_to_cart_button_container = "index-module--add_to_cart_button_container--808a9";
export var count = "index-module--count--ee288";
export var grams = "index-module--grams--a5121";
export var item__price = "index-module--item__price--6d792";
export var item__price_discount = "index-module--item__price_discount--5121a";
export var old_price = "index-module--old_price--d4d3a";
export var select_header = "index-module--select_header--5533f";
export var special_title = "index-module--special_title--26ea2";
export var submit__container = "index-module--submit__container--b853d";
export var weight_section = "index-module--weight_section--ed9f1";