import React from "react";
import { navigate } from "gatsby";

import { getLocationId } from "../index";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";

import * as tgsStyles from "../variants/productlocations-tgs.module.css";

const DEFAULT_LOCATION_IMG =
  "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltf672c7de8319b4df/63c18ca31779f2026027c63f/tgs-location.jpg";

const ProductLocationsList = (props) => {
  // console.log("ProductLocationsList props", props);

  const locationsList = props.data;
  const hasProductCheck = props.hasProductCheck || false;
  const posthog = props?.posthog;

  const toggleClass = (index) => {
    props.setClasses(index);
  };

  let styles = tgsStyles.default || tgsStyles;
  let theme = "tgsSelect";

  switch (props.variant) {
    case "tgs":
      styles = tgsStyles.default || tgsStyles;
      theme = "tgsSelect";
      break;
  }

  if (!locationsList?.length) return null;

  return (
    <ul>
      {locationsList?.map((coord) => {
        const location = props?.locationsByUrl?.[coord?.url];
        const validateProducts = props.availableAt.includes(
          parseInt(coord?.content?.vendor_integrations?.jane_rec_id)
        )
          ? true
          : false;
        const isCurrent =
          props.currentStore &&
          location.content.id === props.currentStore.content.id;

        return (
          (validateProducts || isCurrent) && (
            <li
              id={getLocationId(coord.url)}
              key={getLocationId(coord.url)}
              className={
                props.classes == location?.url ? `${styles.active}` : null
              }
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                props.setClasses(location?.url);
                const link = `${location.url}?redirect=${encodeURIComponent(
                  window.location.pathname
                )}`;
                navigate(link);

                capturePosthogLink({
                  eventName: `${posthogEvents.PRODUCT_LOCATIONS}: ${posthogEvents.LOCATION_LINK_CLICKED}`,
                  title: location.content.nickname,
                  to: link,
                  posthog,
                });
              }}
            >
              <div className={styles.location__container}>
                <div className={styles.location__info}>
                  <div className={styles.location__header_info}>
                    <h4>{`TGS ${
                      location.content.nickname &&
                      !!location.content.nickname.length
                        ? ":"
                        : ""
                    } ${location.content.nickname}`}</h4>
                    <button
                      className={
                        props.currentStore &&
                        location.content.id === props.currentStore.content.id
                          ? `${styles.pickup__button_diable}`
                          : validateProducts
                          ? `${styles.pickup__button}`
                          : `${styles.pickup__button_diable}`
                      }
                    >
                      {`${
                        props.currentStore &&
                        location.content.id === props.currentStore.content.id
                          ? "Your Location"
                          : validateProducts
                          ? "Pick Up Here"
                          : "Out of Stock"
                      }`}
                    </button>
                  </div>
                  <div className={styles.location__address}>
                    <p>{location.content.address_1}</p>
                    <p>
                      {location.content.city}, {location.content.state}{" "}
                    </p>
                  </div>
                  <span>{location.content.telephone}</span>
                  <h5
                    className={
                      validateProducts
                        ? `${styles.instock_location}`
                        : `${styles.notinstock_location}`
                    }
                  >{`${
                    props.currentStore &&
                    location.content.id === props.currentStore.content.id
                      ? "Your Location"
                      : validateProducts
                      ? "In Stock"
                      : "NOT Available at this location"
                  }`}</h5>
                  <p className={styles.view_locations}>View Location Details</p>
                </div>
              </div>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default ProductLocationsList;
