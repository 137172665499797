import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { InstantSearch, Hits, Configure } from "react-instantsearch";
import { map as _map, isEqual as _isEqual } from "lodash";

import { priceIdMap } from "../../providers/iheartjane/jane-embed";
import ResultItem from "./result-item";
import NoResultsBoundary from "./no-result";
import ProductsPanel from "../product-panel";
import { mappedDiscountProductIds } from "../../utilities/index";
import { specialPriceKeys, posthogEvents } from "../../utilities/constants";
import LazyLoadingImage from "../lazy-loading-image";

import * as productDetailStyles from "./productdetail.module.css";
import BackArrow from "../../elements/icons/BackArrow.js";
import "./facets.css";
import "./algolia.css";

// Find the first non-null special price property
const specialPriceProperty = (hit) =>
  specialPriceKeys?.find(
    (key) => hit && hit[key] !== null && hit[key] !== undefined
  );

const ProductDetail = (props) => {
  const styles = productDetailStyles.default || productDetailStyles;
  const [currentItem, setCurrentItem] = useState(null);
  const [currentHit, setCurrentHit] = useState(null);
  const [showCartModal, setShowCartModal] = useState(false);
  const [discountItems, setDiscountItems] = useState([]);

  const Modal = props.modal;
  const Button = props.button;

  const actions = props.actions;
  const storeId = props.storeId || props.productData.store_id;
  const cartData = props.cartData;
  // from SSR
  const productData = props.productData;
  const locations = props.locations;
  const posthog = props?.posthog;

  const locationsByStoreId = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const currentStore = locationsByStoreId[storeId];

  useEffect(() => {
    if (props.specialsData?.data?.rec && currentHit?.unique_slug) {
      // console.log(props.specialsData?.data?.rec);
      let discountProducts = mappedDiscountProductIds(
        currentHit,
        props.specialsData
      );

      if (
        currentHit?.product_id &&
        discountProducts?.indexOf(currentHit?.product_id) >= 0
      ) {
        discountProducts.splice(
          discountProducts?.indexOf(currentHit?.product_id),
          1
        );
      }

      if (discountProducts?.length) {
        setDiscountItems(discountProducts);
      }
    }
  }, [props.specialsData, currentHit]);

  // this is the SSR product tied to the store id
  const itemData =
    props.productData.available_products[parseInt(props.storeId)];

  useEffect(() => {
    if (itemData) {
      if (!currentItem && currentHit) {
        let _item;
        // this is awful circumstance but a good hack!
        // for flower, `available_weights` works as expected
        // but `inventory_potencies` inexplicably does not.
        // for things like merch and gear, `inventory_potencies`
        // does the trick. lol.
        const items =
          currentHit.available_weights && currentHit.available_weights[0]
            ? currentHit.available_weights
            : currentHit.inventory_potencies
            ? currentHit.inventory_potencies.map((v) => v.price_id)
            : null;
        items &&
          items.map(function (item) {
            if (currentHit[`price_${priceIdMap[item]}`] && !_item) {
              _item = true;
              setCurrentItem(priceIdMap[item]);
            }
          });
      }
    }
  }, [itemData, currentHit]);

  const janeAlgoliaIndex = "menu-products-production";

  const productFilters = itemData
    ? `objectID:${itemData?.id}`
    : productData.product_id;

  if (!productFilters) {
    return null;
  }

  if (!props.searchClient || !props?.searchClient?.appId) {
    return null;
  }

  return (
    <section
      className={`${styles.product_detail_container}`}
      id="product_detail_container"
    >
      <div className={styles.item__top_buttons}>
        <div>
          <a
            href="/shop"
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0, 0, 0, 0)",
              border: "0",
              textDecoration: "none",
              color: "transparent",
            }}
          >
            The Green Solution Menu
          </a>
          <Button
            theme="tgsTransparentGreen"
            label={
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <BackArrow className={styles.backArrow} />
                TGS {currentStore?.content?.nickname} Menu
              </div>
            }
            onClick={() => {
              navigate(currentStore?.content?.id ? `/shop/${currentStore?.content?.id}` : '/locations');
            }}
          />
        </div>
        {/* <div>
          <Button
            theme="cart"
            label={<CartIcon />}
            onClick={(e) => {
              setShowCartModal(true);
            }}
          />
        </div> */}
      </div>
      <Modal
        buttonOpts={["tgssmall", "tgsTransparent"]}
        modalTitle={`Cart`}
        variant={`cart`}
        dismissModal={() => {
          setShowCartModal(false);
          posthog?.capture(posthogEvents.ADD_TO_CART_MODAL_CLOSED);
          console.info(
            `Posthog event -> ${posthogEvents.ADD_TO_CART_MODAL_CLOSED}`
          );
        }}
        extraClasses="modal-dialog-cart"
        hide={!showCartModal}
        currentStore={currentStore}
        shouldCloseOnOverlayClick={true}
        actions={actions}
        user={props.user}
        closeTimeoutMS={100}
        posthog={posthog}
      >
        {!!discountItems?.length && (
          <ProductsPanel
            panelKey={"cart-modal"}
            productIds={discountItems}
            specialsProductIds={discountItems}
            filterBy="product_id"
            specialPriceEach={currentHit?.special_price_each}
            searchClient={props.searchClient}
            indexName={janeAlgoliaIndex}
            actions={actions}
            setShowCartModal={setShowCartModal}
            isResponsive
            hasMixMatch={
              currentHit?.applicable_special_types?.indexOf("bundle") > -1
            }
            currentStore={currentStore}
            cartData={cartData}
            bundleTitle={currentHit?.special_title}
            bundleDollarAmount={
              currentHit?.[specialPriceProperty(currentHit)]?.discount_amount
            }
            bundlePercent={
              currentHit?.[specialPriceProperty(currentHit)]?.discount_percent
            }
            posthog={posthog}
          />
        )}
      </Modal>

      <div className={styles.specialsContainer}>
        {typeof window !== "object" ||
        !props.siteConfigData?.env?.jane?.algolia_key ? (
          <ResultItem
            isStaticComponent
            productData={productData}
            setCurrentHit={setCurrentHit}
            setShowCartModal={setShowCartModal}
            discountItems={discountItems}
            searchClient={props.searchClient}
            currentStore={currentStore}
            cartData={cartData}
            posthog={posthog}
            locations={locations}
          />
        ) : (
          <InstantSearch
            searchClient={props.searchClient}
            future={{
              preserveSharedStateOnUnmount: true,
            }}
            indexName={janeAlgoliaIndex}
          >
            <Configure hitsPerPage={1} filters={productFilters} />
            {typeof window !== "object" || (!currentStore && !storeId) ? (
              <ResultItem
                isStaticComponent
                hit={productData}
                productData={productData}
                setCurrentHit={setCurrentHit}
                setShowCartModal={setShowCartModal}
                discountItems={discountItems}
                searchClient={props.searchClient}
                indexName={janeAlgoliaIndex}
                actions={actions}
                currentStore={currentStore}
                cartData={cartData}
                posthog={posthog}
                locations={locations}
              />
            ) : (
              <NoResultsBoundary
                fallback={
                  <ResultItem
                    isStaticComponent
                    hit={productData}
                    productData={productData}
                    setCurrentHit={setCurrentHit}
                    discountItems={discountItems}
                    indexName={janeAlgoliaIndex}
                    actions={actions}
                    searchClient={props.searchClient}
                    posthog={posthog}
                    locations={locations}
                  />
                }
              >
                <Hits
                  hitComponent={(hitProps) => {
                    const newProps = {
                      itemData: itemData,
                      cartData: cartData,
                      productData: productData,
                      discountItems: discountItems,
                      locationsByStoreId: locationsByStoreId,
                      storeId: storeId,
                      currentStore: currentStore,
                      cartActions: actions,
                      currentItem: currentItem,
                      setCurrentItem: setCurrentItem,
                      currentHit: currentHit,
                      setCurrentHit: setCurrentHit,
                      setShowCartModal: setShowCartModal,
                    };
                    const resultItemProps = { ...newProps, ...hitProps };

                    return (
                      <ResultItem
                        indexName={janeAlgoliaIndex}
                        searchClient={props.searchClient}
                        actions={actions}
                        posthog={posthog}
                        locations={locations}
                        {...resultItemProps}
                      />
                    );
                  }}
                />
              </NoResultsBoundary>
            )}
          </InstantSearch>
        )}
      </div>
    </section>
  );
};

export default ProductDetail;
