import React from "react"; // eslint-disable-line

const FacebookIconTgs = ({ color, className, height }) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    role="presentation"
  >
    <circle cx="18.5" cy="18.5" r="18" stroke="#375542" />
    <path
      d="M24 9H21C19.6739 9 18.4021 9.52678 17.4645 10.4645C16.5268 11.4021 16 12.6739 16 14V17H13V21H16V29H20V21H23L24 17H20V14C20 13.7348 20.1054 13.4804 20.2929 13.2929C20.4804 13.1054 20.7348 13 21 13H24V9Z"
      fill="#375542"
    />
  </svg>
);

export default FacebookIconTgs;
