import React from "react"; // eslint-disable-line

const CopyIconTgs = ({ color, className, height }) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
    role="presentation"
  >
    <circle cx="18.5" cy="18.5" r="18" stroke="#375542" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 13.5C13 13.2239 12.7761 13 12.5 13H10.5C10.2239 13 10 13.2239 10 13.5V27.5C10 27.7761 10.2239 28 10.5 28H21.5C21.7761 28 22 27.7761 22 27.5V25.5C22 25.2239 21.7761 25 21.5 25H13.5C13.2239 25 13 24.7761 13 24.5V13.5Z"
      fill="#375542"
    />
    <path
      d="M22 10.2071C22 9.76165 22.5386 9.53857 22.8536 9.85355L24 11L25.5 12.5V12.5C25.6845 12.6845 25.5538 13 25.2929 13H22.5C22.2239 13 22 12.7761 22 12.5V10.2071Z"
      fill="#375542"
    />
    <path
      d="M14 9.5C14 9.22386 14.2239 9 14.5 9H20.5C20.7761 9 21 9.22386 21 9.5V13.5C21 13.7761 21.2239 14 21.5 14H25.5C25.7761 14 26 14.2239 26 14.5V23.5C26 23.7761 25.7761 24 25.5 24H14.5C14.2239 24 14 23.7761 14 23.5V9.5Z"
      fill="#375542"
    />
  </svg>
);

export default CopyIconTgs;
