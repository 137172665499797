import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { Rating } from "react-simple-star-rating";
import {
  InstantSearch,
  Hits,
  RefinementList,
  Highlight,
  Configure,
} from "react-instantsearch-dom";

import * as productDetailReviewsStyles from "./productdetail.module.css";
import "./algolia.css";

const ProductDetailReviews = (props) => {
  const styles = productDetailReviewsStyles.default || productDetailReviewsStyles;
  // console.log("ProductDetailReviews props", props);
  const [currentHit, setCurrentHit] = useState(null);

  const janeAlgoliaIndex = "product-reviews-production";

  if (!props.hit?.aggregate_rating || props.hit?.aggregate_rating <= 0) {
    return null;
  }

  if (!props.searchClient || !props?.searchClient?.appId) {
    return null;
  }

  return (
    <>
      {
        <section
          id="product-reviews"
          className={`${styles.product_detail_reviews_container}`}
        >
          <div className="algolia-container">
            <h3>{"Customer Reviews"}</h3>
            <div className={styles.total_score}>
              <Rating
                readonly={true}
                initialValue={props.hit?.aggregate_rating}
              />
              <div className={styles.total_review_count}>
                Based on {props.hit?.review_count} reviews
              </div>
            </div>
            <InstantSearch
              searchClient={props.searchClient}
              indexName={janeAlgoliaIndex}
            >
              <Configure
                hitsPerPage={10}
                filters={`product_id:${props.productData.product_id}`}
              />
              <Hits
                hitComponent={(props) => (
                  <ResultItem
                    {...Object.assign(
                      { currentHit: currentHit, setCurrentHit: setCurrentHit },
                      props
                    )}
                  />
                )}
              />
            </InstantSearch>
          </div>
        </section>
      }
    </>
  );
};

export default ProductDetailReviews;

const ResultItem = (props) => {
  const styles = productDetailReviewsStyles.default || productDetailReviewsStyles;
  if (props.hit?.hidden) {
    return <p>{`This comment has been hidden`}</p>;
  }
  return (
    <>
      <div className={styles.customer_name}>
        <div className={styles.customer_avatar}>
          {" "}
          {props.hit?.nickname.substring(0, 1).toUpperCase()}
        </div>
        <div> {props.hit?.nickname}</div>
      </div>
      <div>
        <div className={styles.customer_rating}>
          <Rating readonly={true} initialValue={props.hit?.rating} />
          {new Date(props.hit?.updated_at).toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </div>
      </div>
      <div className={styles.customer_comment}>
        {/*<p>{`Has comment: ${props.hit?.has_comment}`}</p>*/}
        <p>{props.hit?.comment}</p>
      </div>
      <div className={styles.customer_feels}>
        {props.hit?.feelings && (
          <div>
            <p>
              <strong>Activities: </strong>{" "}
            </p>
            {props.hit?.activities?.map((item, key) => (
              <span key={key} className={styles.activities}>
                {item}
              </span>
            ))}
          </div>
        )}
        {props.hit?.feelings && (
          <div>
            <p>
              <strong>Feelings: </strong>
            </p>
            {props.hit?.feelings?.map((item, key) => (
              <span key={key} className={styles.activities}>
                {item}
              </span>
            ))}
          </div>
        )}
      </div>

      {/*<p>{`Upvotes: ${props.hit?.upvote_count}`}</p> */}
    </>
  );
};
