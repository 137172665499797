import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ProductLocationsBlock from "../../product-locations/ProductLocations";

import CloseIconBlack from "../../../elements/icons/CloseIconBlack";
import { specialPriceKeys } from "../../../utilities/constants";
import ProductDetailReviews from "./../ProductDetailReviews";

import AddToCartSection from "./add-to-cart-section";
import ProductAvailability from "./product-availability";
import ProductDetailImage from "../ProductDetailImage";
import CategoryTypeAndShare from "../category-and-type-share";
import ProductsPanel from "../../product-panel";

import { getHostname } from "@col-care/common/helpers";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogEvent } from "@col-care/common/utilities/index";

import * as resultItemStyles from "./index.module.css";

const ResultItem = (props) => {
  // console.log({ ResultItem: props });
  const styles = resultItemStyles.default || resultItemStyles;
  const indexName = props.indexName;
  const actions = props.actions;
  const setShowCartModal = props.setShowCartModal;
  const setShowLocationUpdateModal = props.setShowLocationUpdateModal;

  if (!props.searchClient || !props?.searchClient?.appId) {
    return null;
  }

  const searchClient = props.searchClient;
  const posthog = props?.posthog;

  const [isOpen, setIsOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [currentQty, setCurrentQty] = useState(1);

  const clickHandler = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (props.isStaticComponent && props.productData && !props.currentHit) {
      // console.info("setting static data:", props.productData);
      props.setCurrentHit(props.productData);
      // @todo: should refactor the product data to have a field designating it as static
      // this works totally fine for now though
    } else if (!props.isStaticComponent && !props.currentHit?.url_slug) {
      // console.info("setting current hit:", props.hit);
      props.setCurrentHit(props.hit);
    }
  }, [
    props.hit,
    props.currentHit,
    props.setCurrentHit,
    props.isStaticComponent,
  ]);

  const hit = props.currentHit || props.hit || props.productData;

  let theLink = hit
    ? `/shop/${hit.custom_product_type}/${hit.unique_slug}`
    : null;

  const toggleDrawer = () => {
    // console.log("Drawer toggled");
    setIsOpen(!isOpen);
  };
  const mainPhoto = hit?.image_urls?.[0];
  const itemPrice = hit?.[`price_${props.currentItem}`];
  const itemSpecial = hit?.[`special_price_${props.currentItem}`];

  // Define special price keys

  // Find the first non-null special price property
  const specialPriceProperty = specialPriceKeys?.find(
    (key) => hit && hit[key] !== null && hit[key] !== undefined
  );

  // Check if hit has a special price with discount information
  const hasSpecialPrice =
    specialPriceProperty &&
    hit &&
    hit[specialPriceProperty] &&
    (hit[specialPriceProperty].discount_percent !== undefined ||
      hit[specialPriceProperty].discount_amount !== undefined) &&
    hit.special_id !== 1493653;

  // Extract the discount values
  const discountPercent = parseFloat(
    (hit && hit[specialPriceProperty]?.discount_percent) || "0.0"
  );
  const discountAmount = parseFloat(
    (hit && hit[specialPriceProperty]?.discount_amount) || "0.0"
  );

  // Determine the discount type (either 'percent' or 'amount')
  const discountType = discountPercent !== 0 ? "percent" : "amount";

  const bundleDollarAmount =
    props.currentHit?.[specialPriceProperty]?.discount_amount;
  const bundlePercent =
    props.currentHit?.[specialPriceProperty]?.discount_percent;

  return (
    <>
      <div id="item-container" className={styles.item__container}>
        <div className={styles.show__mobile}>
          {hit?.applicable_special_types?.indexOf("bundle") > -1 && (
            <div className={styles.sales__tag_green}>
              <span className={styles.sales__tag_text_mix_container}>
                MIX +<span className={styles.sales__tag_text_mix}> MATCH</span>
              </span>
            </div>
          )}
          {hasSpecialPrice && (
            <div className={styles.sales__tag}>
              <span className={styles.sales__tag_text_container}>
                {discountType === "percent"
                  ? `${discountPercent.toFixed(0)}%`
                  : `$${discountAmount.toFixed(0)} `}
                <span className={styles.sales__tag_text}>OFF!</span>
              </span>
            </div>
          )}

          <h1 className={styles.item__name}>{hit?.name}</h1>
          <h2 className={styles.item__brand}>{hit?.brand}</h2>
          {hit?.aggregate_rating > 0 && hit?.review_count > 0 && (
            <div className={styles.item__rating}>
              <AnchorLink to={`#product-reviews`} title={"Product Reviews"}>
                {`${hit?.review_count} Review${
                  hit?.review_count > 1 ? "s" : ""
                }`}
              </AnchorLink>
            </div>
          )}
          {((hit?.percent_thc !== undefined && hit?.percent_thc !== null) ||
            (hit?.percent_cbd !== undefined && hit?.percent_cbd !== null)) && (
            <div className={styles.item__thc}>
              {hit?.percent_thc && ` THC ${hit?.percent_thc}%`}
              {hit?.percent_cbd && ` CBD ${hit?.percent_cbd}%`}
            </div>
          )}
        </div>

        <ProductDetailImage imgWrapperCLass={styles.item__image} hit={hit} />

        <div className={styles.item__info}>
          <div className={styles.hide__mobile}>
            {hasSpecialPrice && (
              <div className={styles.sales__tag}>
                <span className={styles.sales__tag_text_container}>
                  {discountType === "percent"
                    ? `${discountPercent.toFixed(0)}%`
                    : `$${discountAmount.toFixed(0)} `}
                  <span className={styles.sales__tag_text}>OFF!</span>
                </span>
              </div>
            )}
            {hit?.applicable_special_types?.indexOf("bundle") > -1 && (
              <div className={styles.sales__tag_green}>
                <span className={styles.sales__tag_text_mix_container}>
                  MIX +
                  <span className={styles.sales__tag_text_mix}> MATCH</span>
                </span>
              </div>
            )}

            <h1 className={styles.item__name}>{hit?.name}</h1>
            <h2 className={styles.item__brand}>{hit?.brand}</h2>
            {hit?.aggregate_rating > 0 && hit?.review_count > 0 && (
              <div className={styles.item__rating}>
                <AnchorLink to={`#product-reviews`} title={"Product Reviews"}>
                  {`${hit?.review_count} Review${
                    hit?.review_count > 1 ? "s" : ""
                  }`}
                </AnchorLink>
              </div>
            )}
            {((hit?.percent_thc !== undefined && hit?.percent_thc !== null) ||
              (hit?.percent_cbd !== undefined &&
                hit?.percent_cbd !== null)) && (
              <div className={styles.item__thc}>
                {hit?.percent_thc && ` THC ${hit?.percent_thc}%`}
                {hit?.percent_cbd && ` CBD ${hit?.percent_cbd}%`}
              </div>
            )}
          </div>

          {props.currentHit && (
            <AddToCartSection
              itemSpecial={itemSpecial}
              mainPhoto={mainPhoto}
              itemPrice={itemPrice}
              productBrand={props.currentHit?.brand}
              productName={props.currentHit?.name}
              productType={props.currentHit?.type}
              currentQty={currentQty}
              setCurrentQty={setCurrentQty}
              posthog={posthog}
              {...props}
            />
          )}
          <div>
            {/* {props.currentHit && ( */}
            <ProductAvailability
              productData={props.productData}
              locationsByStoreId={props.locationsByStoreId}
              currentStore={props.currentStore}
              currentStoreId={props.storeId}
              availableAt={props.productData.available_at}
              show={show}
              clickHandler={clickHandler}
              toggleDrawer={toggleDrawer}
              setCurrentQty={setCurrentQty}
              posthog={props?.posthog}
              cartData={props.cartData}
            />
            {/* )} */}
            {
              <div
                className={`${styles.product_location} ${styles.hide__mobile} ${
                  show ? styles.open : ""
                }`}
              >
                {typeof window === "object" && (
                  <ProductLocationsBlock
                    variant="tgs"
                    hostname={getHostname()}
                    apiPath={`/page-data/___api/zip-to-coordinates`}
                    currentStore={props.currentStore}
                    availableAt={props.productData.available_at}
                    posthog={posthog}
                    locations={props.locations}
                    cartData={props.cartData}
                  />
                )}
              </div>
            }
            {
              <div
                className={`Drawer__Container ${
                  isOpen && "Drawer__Container--isOpen"
                }`}
              >
                <div className="DrawerContents__Container ">
                  <div className={styles.close_me} onClick={toggleDrawer}>
                    <CloseIconBlack color="#375542" />
                  </div>
                  <div className={styles.check_store_text}>Check Stores</div>
                  {typeof window === "object" && (
                    <ProductLocationsBlock
                      variant="tgs"
                      hostname={getHostname()}
                      apiPath={`/page-data/___api/zip-to-coordinates`}
                      currentStore={props.currentStore}
                      availableAt={props.productData.available_at}
                      posthog={posthog}
                      locations={props.locations}
                      cartData={props.cartData}
                    />
                  )}
                </div>
              </div>
            }
          </div>
          <div className={styles.item__description}>
            <h3>Description: </h3>
            <p>{hit?.description}</p>
          </div>

          <CategoryTypeAndShare
            shareLink={theLink}
            category={props.isStaticComponent ? hit?.product_type : hit?.kind}
            strain_type={props.isStaticComponent ? hit?.lineage : hit?.category}
          />

          <div className={styles.extract__section}>
            <h3>WARNING: Use of Marijuana Concentrate may lead to:</h3>
            <ul>
              <li>
                Psychotic symptoms and/or Psychotic disorder (delusions,
                hallucinations, or difficulty distinguishing reality).
              </li>

              <li>Mental Health Symptoms/Problems. </li>

              <li>
                Cannabis Hyperemesis Syndrome (CHS) (uncontrolled and repetitive
                vomiting).
              </li>

              <li>
                Cannabis use disorder / dependence, including physical and
                psychological dependence.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Trying to use one of the ids of  applicable_bundle_special_ids but not working 1885067 */}
      {/* {!!props?.discountItems?.length && (
        <ProductsPanel
          productIds={discountItems}
          specialsProductIds={discountItems}
          filterBy="product_id"
          special_price_each={hit?.special_price_each}
          actions={actions}
          setShowCartModal={setShowCartModal}
          searchClient={searchClient}
          indexName={indexName}
          hasMixMatch={hit?.applicable_special_types?.indexOf("bundle") > -1}
          currentStore={props.currentStore}
        />
      )} */}

      <ProductsPanel
        productIds={props.discountItems}
        specialsProductIds={props.discountItems}
        filterBy="product_id"
        special_price_each={hit?.special_price_each}
        actions={actions}
        setShowCartModal={setShowCartModal}
        searchClient={searchClient}
        indexName={indexName}
        hasMixMatch={hit?.applicable_special_types?.indexOf("bundle") > -1}
        currentStore={props.currentStore}
        cartData={props.cartData}
        specialId={hit?.special_id}
        bundleTitle={hit?.special_title}
        bundleDollarAmount={bundleDollarAmount}
        bundlePercent={bundlePercent}
        setShowLocationUpdateModal={setShowLocationUpdateModal}
        panelKey={"details-panel-" + hit?.special_id}
        currentHitId={hit?.product_id}
        posthog={posthog}
      />

      {(() => {
        // @todo: obv super fragile lol
        if (props.storeId && props.currentItem) {
          const itemPrice = props.currentHit[`price_${props.currentItem}`];
          const itemSpecialPrice =
            props.currentHit[`special_price_${props.currentItem}`];

          if (
            props.productData.available_at.includes(parseInt(props.storeId))
          ) {
            return (
              <div className={styles.hide__mobile}>
                {/*} <h3>This product is in stock at your chosen location!</h3> */}
              </div>
            );
          } else {
            return (
              <h3>This product is NOT in stock at your chosen location!</h3>
            );
          }
        }
      })()}
      {props.currentHit && <ProductDetailReviews {...props} />}
    </>
  );
};

export default ResultItem;

ResultItem.propTypes = {
  discountItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentHit: PropTypes.any,
  setCurrentHit: PropTypes.func,
  indexName: PropTypes.string,
  searchClient: PropTypes.any,
  isStaticComponent: PropTypes.bool,
  storeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
