import React from "react";
import PropTypes from "prop-types";
import SocialShare from "../social-share";

import * as categoryTypeAndShareStyles from "./index.module.css";

const CategoryTypeAndShare = ({ category, strain_type, shareLink }) => {
  const styles = categoryTypeAndShareStyles.default || categoryTypeAndShareStyles;
  let tagClass = "";
  if (strain_type === "cbd") {
    tagClass = "greenClass";
  } else if (strain_type === "sativa") {
    tagClass = " whiteClass";
  } else if (strain_type === "hybrid") {
    tagClass = "";
  } else if (strain_type === "indica") {
    tagClass = "blueClass";
  }

  return (
    <div className={styles.item__three_category}>
      {category && (
        <div className={styles.item__category}>
          {" "}
          <h3>CATEGORY:</h3>
          <div className={styles.item__subtype} id="item__subtype">
            <span>{category}</span>
          </div>
        </div>
      )}
      {strain_type && (
        <div className={styles.item__category}>
          {" "}
          <h3>STRAIN TYPE:</h3>
          <div className={styles.item__subtype} id="item__category">
            <span className={tagClass}>{strain_type}</span>
          </div>
        </div>
      )}
      <SocialShare shareLink={shareLink} />
    </div>
  );
};

export default CategoryTypeAndShare;

CategoryTypeAndShare.defaultProps = {
  category: "",
  strain_type: "",
  shareLink: "",
};

CategoryTypeAndShare.propTypes = {
  category: PropTypes.string,
  strain_type: PropTypes.string,
  shareLink: PropTypes.string,
};
