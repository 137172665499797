import React from "react";

import CloseIconBlack from "../../elements/icons/CloseIconBlack";

import * as tgsStyles from "./variants/productlocations-tgs.module.css";

const ProductLocationsForm = (props) => {
  // console.log('ProductLocationsForm props', props)
  let styles;
  let searchIcon;
  let theme;

  switch (props.variant) {
    case "tgs":
      styles = tgsStyles.default || tgsStyles;
      theme = "tgsSelect";
      searchIcon =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blte57dfaf8f97f2641/63a0c2d230bc21055fc80983/tgs_search.png";
      break;
  }

  const isError = Boolean(Object.keys(props.errors).length) && props.values.zip;

  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        <div className={styles.search}>
          {(() => {
            if (isError) {
              return (
                <div className={styles.error_message}>
                  *Please Enter a Valid ZIP Code
                </div>
              );
            }
          })()}
          <div className={styles.input_section}>
            <input
              placeholder="Zip Code"
              name="zip"
              className={isError ? styles.errors : null}
              style={{ fontSize: "1.5em" }}
              disabled={
                props.isSubmitting || (props.status && props.status.success)
              }
              onKeyPress={(e) => {
                if (
                  e.key === "Enter" ||
                  (new RegExp(/[0-9]/).test(e.key) &&
                    e.target.value.concat(e.key).length <= 5)
                ) {
                } else {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                props.handleChange(e);
                const strLength = e.target.value.length || 0;
                if (strLength >= 3 && strLength <= 5) {
                  props.setZipTail(e.target.value.substring(3, 5));
                }
              }}
              onBlur={props.handleBlur}
              value={props.values.zip || ""}
            />
            {(() => {
              if (
                !props.values.zip ||
                (props.values.zip.length <= 5 && !props.apiData)
              ) {
                return (
                  <button
                    aria-label="Submit"
                    disabled={
                      props.isSubmitting ||
                      (props.status && props.status.success)
                    }
                    key={`product-locations-form-submit`}
                    id={`product-locations-form-submit`}
                    type="submit"
                  >
                    {/* @todo?? */}
                    <img alt="Search" src={searchIcon} width="23" />
                  </button>
                );
              } else {
                return (
                  <button
                    aria-label="Submit"
                    key={`product-locations-form-reset`}
                    id={`product-locations-form-reset`}
                    type="button"
                    onClick={(e) => {
                      props.setActiveClasses(false);
                      props.resetForm();
                    }}
                  >
                    <CloseIconBlack color="#375542" />
                  </button>
                );
              }
            })()}
          </div>
        </div>
      </div>
      <div></div>
    </form>
  );
};

export default ProductLocationsForm;
