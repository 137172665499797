import React from "react";
import { Helmet } from "react-helmet";
import HitImage from "../product-category-hit-image";

function arePropsEqual(prevProps, nextProps) {
  // console.log('arePropsEqual', prevProps, nextProps);
  return prevProps.image === nextProps.image;
}

const ProductDetailImage = React.memo(function ProductDetailImage(props) {
  // console.log("Rendering ProductDetailImage", props);

  return (
    <div className={`${props.imgWrapperCLass ?? ""}`}>
      <HitImage hit={props.hit} preload={true} />
    </div>
  );
}, arePropsEqual);

export default ProductDetailImage;
