import React, { useEffect } from "react";
import { Link } from "gatsby";
import ArrowIcon from "../../../../elements/icons/Arrow";
import { posthogEvents } from "@col-care/common/utilities/constants";
import {
  capturePosthogLink,
  capturePosthogEvent,
} from "@col-care/common/utilities/index";

import * as productAvailabilityStyles from "./index.module.css";

const ProductAvailability = (props) => {
  // console.log({ ProductAvailabilityProps: props });
  const styles = productAvailabilityStyles.default || productAvailabilityStyles;
  const posthog = props?.posthog;
  const show = props.show;

  useEffect(() => {
    if (!props.currentStoreId) {
      props.clickHandler(); // Toggle 'show' in ResultItem
    }
  }, []);

  if (
    props.availableAt.includes(parseInt(props.currentStoreId)) ||
    props.availableAt?.length
  ) {
    return (
      <>
        <div className={styles.pickup_location}>
          {props.currentStoreId && (
            <div className={styles.inout__container}>
              <h4>{`Pickup at TGS ${props.currentStore?.content?.nickname}:`}</h4>
              <p className={styles.stock__box}>In Stock </p>
            </div>
          )}
          <div className={styles.show__mobile}>
            <div className={styles.pickup_link}>
              <div onClick={props.toggleDrawer}>
                Select A {props.currentStoreId ? "Different" : ""} Location
              </div>
            </div>
          </div>
          <div className={styles.hide__mobile}>
            <div className={styles.pickup_link}>
              <div
                onClick={() => {
                  props.clickHandler();
                  if (posthog?.capture) {
                    const title = `Select a different location drawer ${
                      !show ? "opened" : "closed"
                    }`;

                    capturePosthogEvent({
                      eventName: posthogEvents.PRODUCT_FINDER,
                      title,
                      posthog,
                      databag: { cartData: props.cartData },
                    });
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                Select A {props.currentStoreId ? "Different" : ""} Location
                <ArrowIcon
                  className={`${styles.arrow__icon}  ${
                    props.show ? styles.opensvg : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.pickup_location}>
          <div className={styles.inout__container}>
            <h4>{`Not available at TGS ${props.currentStore?.content?.nickname}:`}</h4>
            <p className={styles.stock__box_out}>Not In Stock</p>
          </div>
          <Link
            to="/locations"
            onClick={() => {
              capturePosthogLink({
                eventName: posthogEvents.LOCATION_LINK_CLICKED,
                title: "Select A Different Location",
                to: "/locations",
                posthog,
              });
            }}
            className={styles.pickup_link}
          >
            Select A Different Location
          </Link>
        </div>
      </>
    );
  }
};

export default ProductAvailability;
